import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
export default defineComponent({
  name: "HomeView",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const list = router.getRoutes().filter(v => v.meta.isShow);
    const delToken = () => {
      localStorage.removeItem("token");
      // router.push('/login')
    };

    return {
      list,
      active: route.path,
      delToken
    };
  },
  components: {}
});